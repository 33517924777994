export const SET_NAME = "SET_NAME";
export const SET_TYPO_VISIBLE = "SET_TYPO_VISIBLE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const setName = (name) => (dispatch) => {
  dispatch({
    type: SET_NAME,
    payload: name
  });
};

export const setTypoVisible = (typoVisible) => (dispatch) => {
  dispatch({
    type: SET_TYPO_VISIBLE,
    payload: typoVisible
  });
};

export const setUserDetails = (userDetails) => (dispatch) => {
    dispatch({
      type: SET_USER_DETAILS,
      payload: userDetails
    });
  };

// export const setAge = age => dispatch => {
//     dispatch({
//         type: SET_USER_AGE,
//         payload: age,
//     });
// };

// export const increaseAge = age => dispatch => {
//     dispatch({
//         type: INCREASE_AGE,
//         payload: age,
//     });
// };
