import React, {useState, useEffect} from 'react'
import Colors from '../../constants/Colors'
import HomeAppBarHead from '../../components/HomeAppBarHead'
import { useNavigate } from 'react-router-dom'
import { isExpired, decodeToken } from "react-jwt";


function CustomersPage() {

  const navigate = useNavigate()

  const [token, settoken] = useState("")
  const [userDetails, setUserDetails] = useState({})

  useEffect(() => {
    let tokenGot = localStorage.getItem("AppToken-SSC")
    console.log(tokenGot)
    settoken(tokenGot)
    const myDecodedToken = decodeToken(tokenGot);
    const isMyTokenExpired = isExpired(tokenGot);
    console.log(myDecodedToken)
    setUserDetails(myDecodedToken)
    if (isMyTokenExpired) {
      localStorage.removeItem("AppToken-SSC")
      navigate("/")
      return
    }
    setUserDetails(myDecodedToken)
  }, [])
  return (
    <div style={{ backgroundColor: Colors.BACKGROUND_COLOR }} >

      <div style={{ display: "flex", height: "100vh", flexDirection: "column", flex: 1 }} >
        <HomeAppBarHead userDetails={userDetails} page="Customers" />
        <h1 style={{color:"white", textAlign:"center", marginTop:"50px"}}>Coming Soon</h1>

        {/* <ProductsComponent page={"products"} />

        <div>
          <HomeFooterMenu tabValue={1} />
        </div> */}


      </div>


      {/* <ToastContainer position='bottom-right' /> */}
    </div>
  )
}

export default CustomersPage