import { Box, CircularProgress, Stack, Typography, Fab, Paper, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText } from '@mui/material'
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import Colors from '../constants/Colors'
import * as Styled from '../pages/ProductPage/styles';
import axios from 'axios'
import APIClient from '../constants/APIClient';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom'
import { isExpired, decodeToken } from "react-jwt";
import LoadingScreen from './LoadingScreen';
import Fonts from '../constants/Fonts';
import AddIcon from '@mui/icons-material/Add';


const ProductsComponent = forwardRef((props, ref) => {
    const navigate = useNavigate()
    
    const [pageLoading, setpageLoading] = useState(true)
    const [dialogTitle, setdialogTitle] = useState("Add Item")
    const [dialogBtnTxt, setdialogBtnTxt] = useState("Add")
    const [isDialogOpen, setisDialogOpen] = useState(false)
    const [itemCategoryName, setitemCategoryName] = useState("")
    const [itemName, setitemName] = useState("")
    const [itemPrice, setitemPrice] = useState("")
    const [itemID, setitemID] = useState("")

    // useImperativeHandle(ref, () => ({getAddedBillItems: () => {return addedBillItems}}), [addedBillItems]);
    const [searchQuery, setsearchQuery] = useState()
    const [selectedTab, setselectedTab] = useState(0)
    const [selectedTabProductList, setselectedTabProductList] = useState([])
    const [productCategories, setproductCategories] = useState([])
    const [productList, setProductList] = useState([])
    const [token, settoken] = useState("")
    const [userDetails, setuserDetails] = useState({})

    const billItemCheck = (item) =>{
        let hasProductID = (props.addedBillItems).some( prevItem => prevItem['productID'] === item['productID'] )
        if(hasProductID) {return true}
        else {return false;}
    }
    const onSearchBtnClick = () => {
        var condition = new RegExp(searchQuery.toLowerCase());
        var result = productList.filter(function (el) {
            return condition.test((el.productName).toLowerCase());
        });
        setselectedTab(0)
        setselectedTabProductList(result)

    }
    const onProductTabClick = (tabNo, item) => {
        setsearchQuery("")
        setselectedTab(tabNo)
        if (item === "All") {
            setselectedTabProductList(productList)
            return
        }
        setselectedTabProductList(productList.filter((el) => {
            return el.productCategoryName === item
        }))
    }

    const deleteProducts = async (productID) => {
        let productDetails = { productID: productID }
        await axios
            .post(APIClient.API_BASE_URL + "/deleteProducts", productDetails, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (!response.data.success) {
                    // alert(response.data.message)
                    toast.success('Product Deleted Successfully');
                    //   navigate("/")
                    return
                }
                toast.success(response.data.message);
                getProducts(token, userDetails)
            }).catch((error) => {
                console.log(error)
                if (error.name === "AxiosError") {
                    alert("Server Down")
                }
            })

    }
    const getProducts = async (token, myDecodedToken) => {
        let productDetails = { email: myDecodedToken.userEmail }
        await axios
            .post(APIClient.API_BASE_URL + "/getProducts", productDetails, {
                headers: {
                    'authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (!response.data.success) {
                    //   navigate("/")
                    return
                }
                setProductList(response.data.message)
                let productData = response.data.message
                let filteredCategories = [...new Set(productData.map((item, index) => {
                    return item.productCategoryName
                }))].sort()
                setproductCategories(["All", ...filteredCategories])

            }).catch((error) => {
                console.log(error)
                if (error.name === "AxiosError") {
                    alert("Server Down")
                }
            })
    }

    const onAddFabBtnClick = (post, value1, value2, value3, value4) => {
        console.log("Add Btn Clicked")
        if (post === "edit") {
            setitemCategoryName(value1)
            setitemName(value2)
            setitemPrice(value3)
            setitemID(value4)
            setdialogBtnTxt("Update")
            setdialogTitle("Edit Item")
            setisDialogOpen(true)
            return
        }
        setdialogBtnTxt("Add")
        setdialogTitle("Add Item")
        setisDialogOpen(true)
    }

    const onProductAdd = async (post) => {
        if (post === "Add") {
            let productDetails = {
                "email": userDetails.userEmail,
                "itemCategoryName": itemCategoryName,
                "itemName": itemName,
                "itemPrice": itemPrice
            }
            await axios
                .post(APIClient.API_BASE_URL + "/addProducts", productDetails, {
                    headers: {
                        'authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    if (!response.data.success) {
                        // alert(response.data.message)
                        toast.error(response.data.message);
                        //   navigate("/")
                        return
                    }
                    setitemCategoryName("")
                    setitemName("")
                    setitemPrice("")
                    getProducts(token, userDetails)
                    toast.success('Product Added Successfully');
                    //   setProductList(response.data.message) 
                })
                .catch((error) => {
                    console.log(error)
                    if (error.name === "AxiosError") {
                        alert("Server Down")
                    }
                })
        }
        else {
            let productDetails = {
                "email": userDetails.userEmail,
                "productID": itemID,
                "newItemCategoryName": itemCategoryName,
                "newItemName": itemName,
                "newItemPrice": itemPrice
            }
            await axios
                .post(APIClient.API_BASE_URL + "/updateProducts", productDetails, {
                    headers: {
                        'authorization': token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    if (!response.data.success) {
                        // alert(response.data.message)
                        //   navigate("/")
                        return
                    }
                    setitemCategoryName("")
                    setitemName("")
                    setitemPrice("")
                    getProducts(token, userDetails)
                    //   setProductList(response.data.message) 
                })
                .catch((error) => {
                    console.log(error)
                    if (error.name === "AxiosError") {
                        alert("Server Down")
                    }
                })
        }
        setisDialogOpen(false)
    }


    const onDialogClose = () => {
        setisDialogOpen(false)
        setitemCategoryName("")
        setitemName("")
        setitemPrice("")
    }


    const onBillItemAdd = (item) => {
        if(billItemCheck(item)) {
            let filteredObjArr = (props.addedBillItems).filter((prevItem) => prevItem.productID !== item['productID']);
            props.setaddedBillItems(filteredObjArr);
            return
        }
        let billItem = {...item, productQuantity:1, productTotalPrice: item.productPrice}
        props.setaddedBillItems(prevItem => [...prevItem,billItem] );
    }
    

    useEffect(() => {
        onProductTabClick(0, productCategories[0])
    }, [productList, productCategories])
    console.log("Products Components")
    useEffect(() => {
        console.log("Products Components")
        let tokenGot = localStorage.getItem("AppToken-SSC")
        settoken(tokenGot)
        const myDecodedToken = decodeToken(tokenGot);
        const isMyTokenExpired = isExpired(tokenGot);
        setuserDetails(myDecodedToken)
        if (isMyTokenExpired) {
            localStorage.removeItem("AppToken-SSC")
            navigate("/")
            return
        }
        getProducts(tokenGot, myDecodedToken)
        setpageLoading(false)
    }, [])

    return (
        <>
            {
                pageLoading ?
                    <LoadingScreen />
                    :
                    <>
                        <div>
                            <Styled.SearchBlock>
                                <Styled.PageTitle>Products</Styled.PageTitle>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <Styled.SearchInput value={searchQuery}
                                        onChange={(e) => {
                                            setsearchQuery(e.target.value)
                                        }}
                                        placeholder='Search Products...' type="text" />
                                    <Styled.SearchButton onClick={onSearchBtnClick} variant="contained">Search</Styled.SearchButton>
                                </div>
                            </Styled.SearchBlock>
                            <Styled.TabBox >
                                {
                                    productCategories.map((item, index) => (
                                        <Styled.TabItem className={index === selectedTab ? "selectedTab" : "normalTab"} onClick={() => { onProductTabClick(index, item) }} >
                                            {item}
                                        </Styled.TabItem>
                                    ))
                                }
                            </Styled.TabBox>

                        </div>
                        <div style={{ display: "block", overflow: "auto", flex: 1 }}>
                            <Stack direction={"row"} gap={4} sx={{ flexWrap: "wrap" }} justifyContent={"center"}>

                                {
                                    selectedTabProductList.map((item, index) => {
                                        return (
                                            <Styled.ProductBox>
                                                <div className='flex flex-col'>
                                                    <div style={{ display: "flex", marginTop: "20px", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px" }}>
                                                        <Styled.Text className='productName'>{item.productName}</Styled.Text>
                                                        <Styled.Text className='productPrize'>{item.productPrice}</Styled.Text>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: "30px", justifyContent:  props.page==="home"?"center":"space-between", paddingLeft: "20px", paddingRight: "20px" }}>
                                                        {
                                                            props.page === "home" ?
                                                                <>
                                                                    <Styled.ProductBoxBtn className={billItemCheck(item)?"remove":"add"}
                                                                        onClick={() => { onBillItemAdd(item) }}
                                                                    >
                                                                       {billItemCheck(item)?"Remove Item":"Add Item"}
                                                                        
                                                                        </Styled.ProductBoxBtn>
                                                                </>
                                                                :
                                                                <>
                                                                    <Styled.ProductBoxBtn className='edit'
                                                                        onClick={() => { onAddFabBtnClick("edit", item.productCategoryName, item.productName, item.productPrice, item.productID) }}
                                                                    >Edit</Styled.ProductBoxBtn>
                                                                    <Styled.ProductBoxBtn className='del'
                                                                        onClick={() => { deleteProducts(item.productID) }}
                                                                    >Delete</Styled.ProductBoxBtn>
                                                                </>
                                                        }
                                                    </div>
                                                </div>

                                            </Styled.ProductBox>
                                        )
                                    })
                                }
                            </Stack>
                        </div>
                    </>
            }

            <Fab  onClick={() => { onAddFabBtnClick("add", "value1", "value2", "value3") }} variant="extended" aria-label="add" sx={{
                position: "fixed", backgroundColor: Colors.MAIN_COLOR, color: "white",
                bottom: (theme) => theme.spacing(2), fontFamily: Fonts.MAIN_FONT,
                right: (theme) => theme.spacing(2), display: props.page==="home"?"none":"flex"
            }}>
                <AddIcon sx={{ mr: 1 }} />
                Add Product
            </Fab>
            <Dialog open={isDialogOpen}

            >
                <Box width={{ sm: 400, xs: 300 }} height={400} >
                    <Stack gap={3}>
                        <Typography fontSize={{ lg: 20, md: 20, sm: 20, xs: 20 }} sx={{ backgroundColor: Colors.MAIN_COLOR, color: "white", paddingTop: "10px", paddingBottom: "10px" }}
                            fontWeight="bold"
                            fontFamily={Fonts.MAIN_FONT}
                            textAlign={"center"}
                        >{dialogTitle}</Typography>

                        <div style={{ marginLeft: "20px", marginRight: "30px", gap: "5px", display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "16px" }}>Category Name</div>
                            <input
                                value={itemCategoryName}
                                onChange={(e) => { setitemCategoryName(e.target.value) }}
                                style={{ height: "35px", width: "100%", fontSize: "18px", paddingLeft: "10px" }}></input>
                        </div>
                        <div style={{ marginLeft: "20px", marginRight: "30px", gap: "5px", display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "16px" }}>Product Name</div>
                            <input
                                value={itemName}
                                onChange={(e) => { setitemName(e.target.value) }}
                                style={{ height: "35px", width: "100%", fontSize: "18px", paddingLeft: "10px" }}></input>
                        </div>
                        <div style={{ marginLeft: "20px", marginRight: "30px", gap: "5px", display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "16px" }}>Product Price</div>
                            <input
                                value={itemPrice}
                                onChange={(e) => { setitemPrice(e.target.value) }} type={"number"}
                                style={{ height: "35px", width: "100%", fontSize: "18px", paddingLeft: "10px" }}></input>
                        </div>

                        <div style={{
                            marginLeft: "20px", marginRight: "30px", gap: "25px", display: "flex", flexDirection: "row", justifyContent: "flex-end"
                            , alignItems: "center"
                        }}>
                            <div style={{ cursor: "pointer" }} onClick={onDialogClose}>Cancel</div>
                            <Styled.ModalAddButton style={{}} onClick={() => { onProductAdd(dialogBtnTxt) }}>{dialogBtnTxt}</Styled.ModalAddButton>
                        </div>
                    </Stack>
                </Box>
            </Dialog>
        </>
    )
});

export default ProductsComponent