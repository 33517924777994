import { Box } from '@mui/system'
import React from 'react'
import AppBarHead from '../../components/AppBarHead'
import LandingImage from '../../assets/images/landingPageImages/landingImage.jpg'
import { Button, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Colors from '../../constants/Colors';
import Fonts from '../../constants/Fonts';
import { featuresData } from './constants';

function LandingPage() {
  const navigate = useNavigate()

  const onGetStartedBtnClick = () => {
    navigate("/register")
  }

  return (
    <Box>
      <AppBarHead />
      <div
        style={{
          padding: "10% 0 10% 5%",
          backgroundImage: `url(${LandingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Stack gap={0}>
          <Typography fontWeight={"bold"} fontSize={{ lg: 45, md: 45, sm: 30, xs: 25 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
            Smart Shop Calculator
          </Typography>
          <Typography fontWeight={"light"} fontSize={{ lg: 25, md: 25, sm: 20, xs: 20 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
            A Handy Calculator for Shop Owners
          </Typography>
          <Button variant='contained' sx={{ width: { lg: "10%", md: "30%", sm: "25%", xs: "40%" }, bgcolor: Colors.MAIN_COLOR, mt: 4, fontFamily: Fonts.MAIN_FONT, }} onClick={onGetStartedBtnClick}>Get Started</Button>
        </Stack>

      </div>



      <Box sx={{ mt: 5, mb: 5 }}>
        <Stack gap={2}>
          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={{ lg: 25, md: 25, sm: 20, xs: 20 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
            About
          </Typography>
          <Typography textAlign={"center"} fontSize={{ lg: 20, md: 20, sm: 18, xs: 18 }} color={Colors.BLACK_COLOR} fontFamily={Fonts.MAIN_FONT}>
            Smart Shop Calculator is a handy calculator for the Shop. Helps Sellers/ShopKeepers to handle money transactions calculations in an easy way.
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ mt: 10, mb: 5 }}>
        <Stack gap={2}>
          <Typography textAlign={"center"} fontWeight={"bold"} fontSize={{ lg: 25, md: 25, sm: 20, xs: 20 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
            Features
          </Typography>
          <Stack
            direction={'row'}
            justifyContent={"center"}
            alignItems={"center"}
            mt={5}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ flexWrap: "wrap" }}
            gap={10}
          >
            {
              featuresData.map((obj, i) => (
                <Stack
                  key={i}
                  direction={"column"}
                  width={300}
                  alignItems={"center"}
                  gap={2}
                >
                  <div >
                    <img src={obj.image} />
                  </div>
                  <div >
                    <Typography
                      fontSize={{ lg: 20, md: 18, sm: 18, xs: 16 }}
                      sx={{ color: Colors.MAIN_COLOR, textAlign: 'center', fontWeight: 'bold', fontFamily: Fonts.MAIN_FONT, }}> {obj.title} </Typography>
                  </div>
                  <div >
                    <Typography
                      fontSize={{ lg: 18, md: 16, sm: 16, xs: 14 }}

                      sx={{ textAlign: 'center', fontFamily: Fonts.MAIN_FONT, color: Colors.BLACK_COLOR }}> {obj.content} </Typography>
                  </div>
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      </Box>


      {/* <Box sx={{ mt: 10, mb: 5 }}>
                <Stack gap={2} sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Typography textAlign={"center"} fontWeight={"bold"} fontSize={{ lg: 25, md: 25, sm: 20, xs: 20 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
                        App Demo
                    </Typography>
                    <iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                        frameborder='0'
                        style={{ height: 500, width: 800 }}
                        allow='autoplay; encrypted-media'
                        allowfullscreen
                        title='video'
                    />
                </Stack>
            </Box> */}

      <Box sx={{ mt: 10, mb: 1 }}>
        <Stack gap={2} direction={"row"} sx={{ justifyContent: "center" }}>
          <Stack>
            <Typography fontSize={{ lg: 18, md: 18, sm: 16, xs: 16 }} color={Colors.MAIN_COLOR} fontFamily={Fonts.MAIN_FONT}>
              © {new Date().getFullYear()}, <Link underline='hover' href="https://smackalpha.com/">SmackAlpha</Link>. All rights reserved. Designed and Developed by <Link underline='hover' href="https://www.linkedin.com/in/paulstevenme/">Paul Steven</Link>
            </Typography>

          </Stack>



        </Stack>
      </Box>


    </Box>
  )
}

export default LandingPage