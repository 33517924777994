import { Toolbar, Button, Typography, Paper, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useState, useEffect, useLayoutEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import * as Styled from './styles';
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from '@mui/icons-material/Home';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LogoutIcon from '@mui/icons-material/Logout';

export default function HomeAppBarHead(props) {

    const navigate = useNavigate()
    // const { userDetails } = useSelector((state) => state.userReducer);

    const theme = useTheme();
    const AppBarData = [
        {
            text: "Home",
            link: "/home"

        },
        {
            text: "Customers",
            link: "/customers"

        },
        {
            text: "Products",
            link: "/products"

        },
        {
            text: "Settings",
            link: "/settings"
        },
        {
            text: "Logout",
        },
    ];

    const onAppBarBtnClick = (obj) => {
        if (obj.text === "Logout") {
            localStorage.removeItem("AppToken-SSC")
            navigate("/")
            return
        }
        navigate(obj.link)
    }


    return (
        <>
            <Styled.AppBarContainer>
                <Styled.Title>
                    {props.userDetails["userShopName"]}
                </Styled.Title>
                <Styled.AppBarMenuDes>
                    {
                        AppBarData.map((item, index) => (
                            <Styled.AppBarMenuBtnDes onClick={() => { onAppBarBtnClick(item) }} style={{color: props.page ===item.text?Colors.BLOUSE_COLOR:Colors.TEXT_COLOR}}>
                                {item.text}
                            </Styled.AppBarMenuBtnDes>

                        ))
                    }
                </Styled.AppBarMenuDes>

                <Styled.LogoutBtn>
                    <LogoutIcon style={{ color: "white" }} />
                </Styled.LogoutBtn>
            </Styled.AppBarContainer>
        </>
    )
}
