import { Box, CircularProgress, Stack } from '@mui/material'
import React from 'react'
import Colors from '../constants/Colors'

function LoadingScreen() {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Stack direction={"column"} gap={3} alignItems={"center"}>
                <CircularProgress style={{color:Colors.BLOUSE_COLOR}}/>
                <div style={{color:Colors.TEXT_COLOR}}>Loading... Please be patient</div>
            </Stack>

        </Box>
    )
}

export default LoadingScreen