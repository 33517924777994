import { Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { React, useState } from 'react'
import Colors from "../../constants/Colors"
import AppBarHead from '../../components/AppBarHead'
import { useNavigate } from 'react-router-dom'
import APIClient from '../../constants/APIClient'
import axios from 'axios'
import bg from '../../assets/images/loginPageImages/bg.jpg'
import Fonts from '../../constants/Fonts'
import Swal from 'sweetalert2'

function RegisterPage() {
  const navigate = useNavigate()
  const [registerDetails, setregisterDetails] = useState({ name: "", email: "", password: "", shopName:"", shopAddress:"" })

  const onTextFieldChange = (e) => {
    setregisterDetails(prevData => ({ ...prevData, [e.target.name]: e.target.value }))
  }

  const onRegBtnClick = async () => {
    console.log("Reg Btn Click")
    console.log(registerDetails)
    const isEmpty = Object.entries(registerDetails).filter(([k, v], i) => !!v)
    console.log(isEmpty)
    if (isEmpty.length !== 5) {
      Swal.fire({
        title: 'Details not Filled',
        text: 'Please Enter all Details',
        icon: 'warning', confirmButtonColor: Colors.MAIN_COLOR
      
      })
      return
    }
    axios
      .post(APIClient.API_BASE_URL + "/register", registerDetails)
      .then((response) => {

        console.log(response)
        if(!response.data.success){
         
          Swal.fire({
            title: response.data.message,
           
            icon: 'warning', confirmButtonColor: Colors.MAIN_COLOR
          
          })
          return
        }
        console.log(response.data)
      
        Swal.fire({
          title: 'Registration Success',
          text: 'Enjoy using Smart Shop Calculator',
          icon: 'success', confirmButtonColor: Colors.MAIN_COLOR
        
        }).then(function() {
          navigate("/login")
      })
      }).catch((error) => {
        console.log(error)
      })
  }
  return (
    <Box sx={{height:"100vh", backgroundImage:"url(" + bg + ")", width:"100%", backgroundSize:"cover"}} >
      <AppBarHead />

      <Grid container justifyContent={{ sm: "center" }} alignItems={{ sm: "center" }} height={"70vh"} >
        <Paper
          elevation={7}
          sx={{
            width: 500,
            height: 700,
            backgroundColor: 'white',
            padding: 5,
            pb: 10,
            boxShadow: { xs: "none", md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)" }

          }}>

          <Stack direction={"column"} gap={4}>
            <Typography fontFamily={Fonts.MAIN_FONT} fontSize={{ lg: 34, md: 34, sm: 30, xs: 30 }} fontWeight={"bold"} textAlign={"center"} color={Colors.MAIN_COLOR} >
              Create an Account
            </Typography>
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize:18 } }} name='name' onChange={(e) => onTextFieldChange(e)} label="Enter Name" variant="outlined" />
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize:18 } }} name='email' onChange={(e) => onTextFieldChange(e)} label="Enter Email" variant="outlined" />
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize:18 } }} name='password' onChange={(e) => onTextFieldChange(e)} type={"password"} label="Enter Password" variant="outlined" />
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize:18 } }} name='shopName' onChange={(e) => onTextFieldChange(e)}  label="Enter Shop Name" variant="outlined" />
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize:18 } }} name='shopAddress' onChange={(e) => onTextFieldChange(e)} multiline
         rows={4} label="Enter Shop Address" variant="outlined" />

            <Button onClick={onRegBtnClick} sx={{ backgroundColor: Colors.MAIN_COLOR, height: 45, fontSize: 22, fontWeight: "bold", fontFamily:Fonts.MAIN_FONT,textTransform:"none" }} variant="contained">Register</Button>
            <Stack direction={"row"} gap={1} justifyContent={"center"}>
              <Typography fontFamily={Fonts.MAIN_FONT} fontSize={{ lg: 18, md: 18, sm: 18, xs: 18 }} color={"gray"} >
                Already have an Account?
              </Typography>
              <Typography fontFamily={Fonts.MAIN_FONT} onClick={() => { navigate("/login") }} fontSize={{ lg: 18, md: 18, sm: 18, xs: 18 }} color={Colors.MAIN_COLOR} sx={{ cursor: "pointer" }}>
                Login
              </Typography>
            </Stack>
          </Stack>
        </Paper >
      </Grid>
    </Box>
  )
}

export default RegisterPage