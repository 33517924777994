import { Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { React, useState } from 'react'
import Colors from "../../constants/Colors"
import AppBarHead from '../../components/AppBarHead'
import { useNavigate } from 'react-router-dom'
import APIClient from '../../constants/APIClient'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux";
import { setUserDetails } from "../../reduxStore/actions";
import { isExpired, decodeToken } from "react-jwt";
import bg from '../../assets/images/loginPageImages/bg.jpg'
import Fonts from '../../constants/Fonts'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../helpers'

// fontSize={{ lg: 22, md: 20, sm: 20, xs: 20 }}
// fontSize={{ lg: 18, md: 18, sm: 18, xs: 18 }}
function LoginPage() {
  const navigate = useNavigate()

  const { userDetails } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const [loginDetails, setloginDetails] = useState({ email: "", password: "" })

  const onTextFieldChange = (e) => {
    setloginDetails(prevData => ({ ...prevData, [e.target.name]: e.target.value }))
  }

  const onKeyPress = (e: any) => { if (e.which == 13) { onLoginBtnClick() } };

  const onLoginBtnClick = async () => {
    const isEmpty = Object.entries(loginDetails).filter(([k, v], i) => !!v)
    if (isEmpty.length !== 2) {
      showToast("Please enter details", "warn")
      return
    }
    axios
      .post(APIClient.API_BASE_URL + "/login", loginDetails)
      .then((response) => {

        console.log(response.data)
        if (!response.data.success && response.data.message === "Invalid EmailID/Password") {
          showToast(response.data.message, "warn")
          return
        }
        localStorage.setItem("AppToken-SSC", response.data.token)

        let token = response.data.token
        console.log(token)
        const myDecodedToken = decodeToken(token);
        const isMyTokenExpired = isExpired(token);
        console.log(myDecodedToken)
        if (isMyTokenExpired) {
          localStorage.removeItem("AppToken-SSC")
          navigate("/")
          return
        }
        // setuserDetails(myDecodedToken)


        dispatch(setUserDetails(myDecodedToken));


        navigate("/home")
        console.log(response.data)
      }).catch((error) => {
        console.log(error)
        if (error.name === "AxiosError") {
          showToast("Server Under Maintainence", "info")
        }
      })

  }

  return (
    <Box sx={{ height: "100vh", backgroundImage: "url(" + bg + ")", width: "100%", backgroundSize: "cover" }} >
      <AppBarHead />

      <Grid container justifyContent={{ sm: "center" }} alignItems={{ sm: "center" }} height={"70vh"} >
        <Paper
          // elevation={7}
          sx={{
            width: 500,
            height: 350,
            backgroundColor: 'white',
            padding: 5,
            pb: 10,
            boxShadow: { xs: "none", md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)" }

          }}>

          <Stack direction={"column"} gap={5}>
            <Typography fontFamily={Fonts.MAIN_FONT} fontSize={{ lg: 34, md: 34, sm: 30, xs: 30 }} fontWeight={"bold"} textAlign={"center"} color={Colors.MAIN_COLOR} >
              Login
            </Typography>
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize: 18 } }} autoComplete='off' name="email" onChange={(e) => { onTextFieldChange(e) }} label="Enter Email" variant="outlined" />
            <TextField InputProps={{ style: { fontFamily: Fonts.MAIN_FONT, fontSize: 18 } }} name="password" onChange={(e) => { onTextFieldChange(e) }} type={"password"} label="Enter Password" variant="outlined" />
            <Button type="submit" onClick={onLoginBtnClick} sx={{ backgroundColor: Colors.MAIN_COLOR, height: 45, fontSize: 22, fontWeight: "bold", fontFamily: Fonts.MAIN_FONT, textTransform: "none" }} variant="contained">Login</Button>
            <Stack direction={"row"} gap={1} justifyContent={"center"}>
              <Typography fontFamily={Fonts.MAIN_FONT} fontSize={{ lg: 18, md: 18, sm: 18, xs: 18 }} color={"gray"} >
                Don't have an Account?
              </Typography>
              <Typography fontFamily={Fonts.MAIN_FONT} onClick={() => { navigate("/register") }} fontSize={{ lg: 18, md: 18, sm: 18, xs: 18 }} color={Colors.MAIN_COLOR} sx={{ cursor: "pointer" }}>
                Create One
              </Typography>
            </Stack>
          </Stack>
        </Paper >
      </Grid>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />


      {/* <Box
        component="img"
        sx={{
          height: 400,
          width: "100%",
         
        }}
        alt="The house from the offer."
        src= {bg}
      /> */}
    </Box>
  )
}

export default LoginPage