import { React, useState, useEffect, useRef } from 'react'
import HomeAppBarHead from '../../components/HomeAppBarHead'
import Colors from '../../constants/Colors'
import { isExpired, decodeToken } from "react-jwt";
import { useNavigate } from 'react-router-dom'
import HomeFooterMenu from '../../components/HomeFooterMenu'
import * as Styled from './styles';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ProductsComponent from '../../components/ProductsComponent'

function ProductPage() {

  const navigate = useNavigate()

  const [token, settoken] = useState("")
  const [userDetails, setUserDetails] = useState({})

  useEffect(() => {
    let tokenGot = localStorage.getItem("AppToken-SSC")
    console.log(tokenGot)
    settoken(tokenGot)
    const myDecodedToken = decodeToken(tokenGot);
    const isMyTokenExpired = isExpired(tokenGot);
    console.log(myDecodedToken)
    setUserDetails(myDecodedToken)
    if (isMyTokenExpired) {
      localStorage.removeItem("AppToken-SSC")
      navigate("/")
      return
    }
    setUserDetails(myDecodedToken)
  }, [])

  return (
    <div style={{ backgroundColor: Colors.BACKGROUND_COLOR }} >

      <div style={{ display: "flex", height: "100vh", flexDirection: "column", flex: 1 }} >
        <HomeAppBarHead userDetails={userDetails} page="Products" />

        <ProductsComponent page={"products"} />

        <div>
          <HomeFooterMenu tabValue={1} />
        </div>


      </div>


      <ToastContainer position='bottom-right' />
    </div>
  )
}

export default ProductPage


// export const AddressText = styled.div<{ id }>`
//   max-width: ${p => (p.id === 'user' ? 70 : 65)}px;
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 130%;
//   display: flex;
//   align-items: center;
//   text-transform: uppercase;
//   display: inline-block;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   color: ${p => (p.id === 'user' ? '#FCC601' : '#FFFFFF')};
// `;