import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProductPage from './pages/ProductPage';
import LandingPage from './pages/LandingPage';
import SettingsPage from './pages/SettingsPage';
import CustomersPage from './pages/CustomersPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<LandingPage />} />
          <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/register' element={<RegisterPage />} />
          <Route exact path='/home' element={<HomePage />} />
          <Route exact path='/products' element={<ProductPage />} />
          <Route exact path='/settings' element={<SettingsPage />} />
          <Route exact path='/customers' element={<CustomersPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
