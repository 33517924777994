import { SET_NAME, SET_TYPO_VISIBLE, SET_USER_DETAILS } from "./actions";

const initialState = {
  name: "",
  typoVisible: false,
  userDetails:{}
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAME:
      return { ...state, name: action.payload };
    case SET_TYPO_VISIBLE:
      return { ...state, typoVisible: action.payload };
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };

    default:
      return state;
  }
}

export default userReducer;
