import { React, useState, useEffect, useRef } from 'react'
import HomeAppBarHead from '../../components/HomeAppBarHead'
import { Stack, Typography, Fab, Paper, IconButton, TextField, Button, Divider, Chip, Dialog, Select, MenuItem, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import Fonts from '../../constants/Fonts'
import Colors from '../../constants/Colors'
import APIClient from '../../constants/APIClient'
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system'
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { isExpired, decodeToken } from "react-jwt";
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '../../components/LoadingScreen'
import axios from 'axios'
import LockIcon from '@mui/icons-material/Lock';
import HomeFooterMenu from '../../components/HomeFooterMenu'
import * as Styled from './styles';
import ProductsComponent from '../../components/ProductsComponent'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, choosenProductNames, theme) {
  return {
    fontWeight:
      choosenProductNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


function HomePage() {
  console.log('HomePage');
  const navigate = useNavigate()
  const theme = useTheme();
  const [userDetails, setUserDetails] = useState({})
  const [pageLoading, setPageLoading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [token, settoken] = useState("")
  const [productList, setProductList] = useState([])
  var productLists = [{ "itemName": "tea", "itemPrice": "₹15" }, { "itemName": "vadai", "itemPrice": "₹5" }, { "itemName": "dosa", "itemPrice": "₹30" }]
  const [subTotal, setSubTotal] = useState(0)
  const [grantTotal, setGrantTotal] = useState(0)
  const [gst, setgst] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [balToGive, setBalToGive] = useState(0)
  const [receivedAmount, setReceivedAmount] = useState(0)
  const [billNo, setBillNo] = useState("0001")

  const [billItems, setBillItems] = useState([
  ])
  const [choosenProductNames, setChoosenProductNames] = useState([]);

  const clearAllBtnClick = () => {
    setChoosenProductNames([])
    setBillItems([])
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setChoosenProductNames(
      typeof value === 'string' ? value.split(',') : value,
    );

  };


  const getProducts = async (token, myDecodedToken) => {
    let productDetails = { email: myDecodedToken.email }
    await axios
      .post(APIClient.API_BASE_URL + "/getProducts", productDetails, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (!response.data.success) {
          // alert(response.data.message)
          //   navigate("/")
          return
        }
        setProductList(response.data.message)
      }).catch((error) => {
        console.log(error)
        if (error.name === "AxiosError") {
          alert("Server Down")
        }
      })
  }



  const onClearBillClick = () => {
    setaddedBillItems([])
    setReceivedAmount(0)
  }

  const onProductAdd = async (post) => {
    let billAddedProducts = billItems.map((item, index) => {
      return item.productName
    })
    if (billAddedProducts.length !== 0) {
      let newlyAddedProducts = choosenProductNames.filter(function (item) {
        return billAddedProducts.indexOf(item) === -1;
      });
      let temp = {}
      let tempArr = []
      for (let i of newlyAddedProducts) {
        let gotProduct = (productList.filter(item => { return item.productName === i }))[0]
        temp = { ...gotProduct, productQuantity: 1, productTotalCost: parseInt(gotProduct["productPrice"]) }
        tempArr.push(temp)
        temp = {}
      }
      setBillItems(oldArray => [...oldArray, ...tempArr]);
      setIsDialogOpen(false)
      return
    }

    let temp = {}
    let tempArr = []

    for (let i of choosenProductNames) {
      let gotProduct = (productList.filter(item => { return item.productName === i }))[0]
      temp = { ...gotProduct, productQuantity: 1, productTotalCost: parseInt(gotProduct["productPrice"]) }
      tempArr.push(temp)
      temp = {}
    }
    setBillItems(oldArray => [...oldArray, ...tempArr]);
    let billItemsTemp = [...billItems, ...tempArr]
    setChoosenProductNames(billItemsTemp.map((item) => { return item.productName }))
    setIsDialogOpen(false)
  }

  useEffect(() => {
    let finalAmount = 0
    var total = 0;
    billItems.forEach(item => {
      total += item.productTotalCost;
    });

    setSubTotal(total)
    finalAmount = finalAmount + total

    if (gst !== 0 && gst < 100) {
      let gstAmount = parseInt(Math.round((finalAmount * gst) / 100))
      finalAmount = finalAmount + gstAmount
    }
    
    if (discount != 0) {
      finalAmount = finalAmount - discount
    }

    finalAmount = finalAmount < 0 ? 0 : finalAmount
    setGrantTotal(finalAmount)
   

  }, [billItems, gst, discount])


  // setSalwarData(salwarData.filter((item) => item.salwarOrderId !== OrderId));


  const onMinusPlusBtnClick = (post, value, index) => {
    if (post === "minus") {
      setaddedBillItems((prev) => prev.map((el) => (el.productID === value && el.productQuantity != 1) ? { ...el, productQuantity: el.productQuantity - 1, productTotalPrice: el.productPrice * (el.productQuantity - 1) } : el));
    }
    else {
      setaddedBillItems((prev) => prev.map((el) => el.productID === value ? { ...el, productQuantity: el.productQuantity + 1, productTotalPrice: el.productPrice * (el.productQuantity + 1) } : el));
    }
  }

  const onDelItemBtnClick = (value) => {
    setBillItems(billItems.filter((item) => item.productName !== value));
    setChoosenProductNames(choosenProductNames.filter((item) => item !== value))
  }

  const onBalAmountUpdate = (value) => {
    let bal = (value - subTotal) < 0 ? 0 : value - subTotal
    setBalToGive(bal)
  }

  useEffect(() => {
    let token = localStorage.getItem("AppToken-SSC")
    const myDecodedToken = decodeToken(token);
    const isMyTokenExpired = isExpired(token);
    if (isMyTokenExpired) {
      localStorage.removeItem("AppToken-SSC")
      navigate("/")
      return
    }
    setUserDetails(myDecodedToken)
    getProducts(token, myDecodedToken)
    setPageLoading(false)



    // var total = 0;
    // billItems.forEach(item => {
    //     total += item.itemTotalCost;
    // });

    // setSubTotal(total)


  }, [])
  const [addedBillItems, setaddedBillItems] = useState([])

  const onDeleteBillItem = (productID) => {
    setaddedBillItems(addedBillItems.filter(data => data.productID !== productID))
  }

  useEffect(() => {
    try {
      let calculatedSubTotal = addedBillItems.map(i => i.productTotalPrice).reduce((a, b) => parseInt(a) + parseInt(b));
      setSubTotal(calculatedSubTotal)
      setGrantTotal(Math.round(parseInt(calculatedSubTotal) + parseInt(calculatedSubTotal * (18 / 100))));
    }
    catch (error) {
      setSubTotal(0)
      setGrantTotal(0)
    }
  }, [addedBillItems])

  useEffect(() => {
    let balance = receivedAmount-grantTotal
    setBalToGive(balance<0?0:balance)
  }, [receivedAmount, grantTotal])
  
  return (
    <div style={{ backgroundColor: Colors.BACKGROUND_COLOR }} >
      {
        pageLoading ?
          <LoadingScreen />
          :
          <Styled.MainContainer >
            {/* AppBar */}
            <div>
              <HomeAppBarHead userDetails={userDetails} page="Home" />
            </div>
            {/* Main Block */}
            <Styled.HomeBlock>
              <Styled.HomeSubBlock>

                <Styled.HomeSubProductsBlock>
                  <div >
                    <ProductsComponent page={"home"} addedBillItems={addedBillItems} setaddedBillItems={setaddedBillItems} />
                  </div>
                  <div>

                  </div>
                </Styled.HomeSubProductsBlock>

                <Styled.HomeSubBillBlock>
                  <div style={{ display: "flex", justifyContent: "center", fontSize: "20px", padding: "5px" }}>Bill No: {billNo}</div>
                  <div style={{ height: "100%", backgroundColor: "#413F42", overflowY: "scroll" }}>
                    {
                      addedBillItems.map((item, index) => (
                        <div style={{ border: `2px solid ${Colors.BLOUSE_COLOR}`, margin: "20px", padding: "10px 20px 10px 20px", borderRadius: "5px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Styled.BillItemBlock>
                              <Styled.Text className='billItemHead'>S.No</Styled.Text>
                              <Styled.Text className='billItemContent'>{index + 1}</Styled.Text>
                            </Styled.BillItemBlock>
                            <Styled.BillItemBlock>
                              <Styled.Text className='billItemHead'>Name</Styled.Text>
                              <Styled.Text className='billItemContent'>{item.productName}</Styled.Text>
                            </Styled.BillItemBlock>
                            <Styled.BillItemBlock>
                              <Styled.Text className='billItemHead'>Price</Styled.Text>
                              <Styled.Text className='billItemContent'>{item.productPrice}</Styled.Text>
                            </Styled.BillItemBlock>
                            <Styled.BillItemBlock>
                              <Styled.Text className='billItemHead'>Quantity</Styled.Text>
                              <Styled.QuantityBlock>
                                <Styled.MinusPlusBtn onClick={() => { onMinusPlusBtnClick("minus", item.productID) }}>-</Styled.MinusPlusBtn>
                                <Styled.QuantityText>{item.productQuantity}</Styled.QuantityText>
                                <Styled.MinusPlusBtn onClick={() => { onMinusPlusBtnClick("plus", item.productID) }}>+</Styled.MinusPlusBtn>
                              </Styled.QuantityBlock>
                            </Styled.BillItemBlock>
                            <Styled.BillItemBlock>
                              <Styled.Text className='billItemHead'>Total Price</Styled.Text>
                              <Styled.Text className='billItemContent'>{item.productTotalPrice}</Styled.Text>
                            </Styled.BillItemBlock>


                            <Styled.DeleteBillItem>
                              <Styled.Text className='billItemDelete' onClick={() => { onDeleteBillItem(item.productID) }}>Delete</Styled.Text>

                            </Styled.DeleteBillItem>
                          </div>


                        </div>
                      ))
                    }
                  </div>
                  <hr />
                  <div style={{ height: "100%", backgroundColor: "#413F42" }}>


                    <div
                      style={{ display: "flex", gap: "10px", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
                      <div style={{ height: "100px", width: "150px", backgroundColor: "black", borderRadius: "6px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                        <div style={{ color: "white", opacity: "0.7" }}>Sub Total</div>
                        <div style={{ color: "white", fontSize: "28px", }}>{subTotal}</div>
                      </div>

                      <div style={{ height: "100px", width: "150px", backgroundColor: "black", borderRadius: "6px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                        <div style={{ color: "white", opacity: "0.7" }}>Tax</div>
                        <div style={{ color: "white", fontSize: "28px", }}>18%</div>
                      </div>

                      <div style={{ height: "100px", width: "150px", backgroundColor: "black", borderRadius: "6px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", border: `1px solid ${Colors.BLOUSE_COLOR}` }}>
                        <div style={{ color: "white", opacity: "0.7" }}>Grant Total</div>
                        <div style={{ color: "white", fontSize: "28px", }}>{grantTotal}</div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px", marginTop: "15px" }}>

                      <div style={{ display: "flex", flexDirection: "column", gap: "10px", }}>
                        <div style={{ color: "white", fontSize: "16px" }}>Enter Received Amount</div>
                        <input
                          type="text"
                          value={receivedAmount}
                          onChange={(e)=>{setReceivedAmount(e.target.value)}}
                          style={{ height: "40px", fontSize: "18px", paddingLeft: "10px", borderRadius: "5px", backgroundColor: "black", border: "1px solid white", color: "white" }} ></input>
                      </div>



                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "black", padding: "10px 20px 10px 20px", borderRadius: "5px", border: `2px solid ${Colors.BLOUSE_COLOR}` }}>
                        <div style={{ fontSize: "16px", color: "gold" }}>
                          Balance Amount to Give
                        </div>
                        <div style={{ fontSize: "25px", color: "gold" }}>
                          {balToGive}
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center", margin: "20px", backgroundColor: "black", padding: "10px 20px 10px 20px", borderRadius:"5px" }}>
                                            <div style={{ fontSize: "16px", color:"white" }}>
                                                Discount (in Amount)
                                            </div>
                                            <div style={{ fontSize: "18px", color:"white" }}>
                                                200
                                            </div>
                                        </div> */}
                    {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px", backgroundColor: "black", padding: "10px 20px 10px 20px", borderRadius: "5px" }}>
                                            <div style={{ fontSize: "16px", color: "white" }}>
                                                Customer Name
                                            </div>
                                            <div style={{ fontSize: "18px", color: "white" }}>
                                                Steven
                                            </div>
                                        </div> */}
                    {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px", backgroundColor: "black", padding: "10px 20px 10px 20px", borderRadius: "5px" }}>
                                            <div style={{ fontSize: "16px", color: "white" }}>
                                                Customer Mob No
                                            </div >
                                            <di style={{ fontSize: "18px", color: "white" }}>
                                                87454548484
                                            </di>
                                        </div> */}

                    <div style={{ display: "flex", margin: "20px 20px 0px 20px", justifyContent: "space-around" }}>
                      <Button variant='contained' onClick={onClearBillClick}>Clear Bill</Button>
                      {/* <Button variant='contained'>Save Bill</Button> */}
                      {/* <Button variant='contained'>Print Bill</Button> */}
                    </div>
                  </div>


                </Styled.HomeSubBillBlock>
              </Styled.HomeSubBlock>
            </Styled.HomeBlock>
            {/* FooterBlock */}
            <div>
              <HomeFooterMenu tabValue={0} />
            </div>

          </Styled.MainContainer>
      }

      {/* Dialog */}

      <Dialog open={isDialogOpen}
        onClose={() => { setIsDialogOpen(false) }}
      >
        <Box width={{ sm: 500, xs: 300 }} height={300} >
          <Stack gap={3}>
            <Typography fontSize={{ lg: 30, md: 30, sm: 26, xs: 26 }} sx={{ backgroundColor: Colors.MAIN_COLOR, color: "white" }}
              fontWeight="bold"
              fontFamily={Fonts.MAIN_FONT}
              textAlign={"center"}
              pt={1}
              pb={1}
            >Add Item for Calculation</Typography>

            <Stack gap={3} ml={2} mr={2}>
              <div>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-multiple-chip-label">Products</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple

                    value={choosenProductNames}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Product" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {productList.map((item) => (
                      <MenuItem
                        key={item.productName}
                        value={item.productName}
                        style={getStyles(item.productName, choosenProductNames, theme)}
                      >
                        {item.productName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Stack>

            <Stack gap={3} ml={2} mr={2} direction={"row"} justifyContent={"flex-end"}>
              <Button onClick={() => { setIsDialogOpen(false) }} sx={{ height: 35, fontSize: 16 }} variant="text">Cancel</Button>
              <Button onClick={() => { onProductAdd("Add") }} sx={{ backgroundColor: Colors.MAIN_COLOR, height: 35, fontSize: 18 }} variant="contained" >Add</Button>

            </Stack>


          </Stack>
        </Box>
      </Dialog>

    </div>
  )
}

export default HomePage