import { Toolbar, Button, Typography, Paper, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useState, useEffect, useLayoutEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import * as Styled from './styles';
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from '@mui/icons-material/Home';
import WidgetsIcon from '@mui/icons-material/Widgets';

export default function HomeFooterMenu(props) {

    const navigate = useNavigate()
    // const { userDetails } = useSelector((state) => state.userReducer);

    const theme = useTheme();
    const AppBarData = [
        {
            text: "Home",
            link: "/home"

        },
        {
            text: "Products",
            link: "/products"

        },
        {
            text: "Logout",
        },
    ];

    const onAppBarBtnClick = (obj) => {
        if (obj.text === "Logout") {
            localStorage.removeItem("AppToken-SSC")
            navigate("/")
            return
        }
        navigate(obj.link)
    }
    const [value, setValue] = useState(0);


    return (
        <>
            <Styled.BottomAppBarContainer>
                <Paper elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={props.tabValue}
                        onChange={(event, newValue) => {
                            console.log(newValue)
                            setValue(newValue);
                            if (newValue == 1) {
                                navigate("/products")
                            }
                            else {
                                navigate("/home")
                            }
                        }}
                    >
                        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                        <BottomNavigationAction label="Products" icon={<WidgetsIcon />} />

                    </BottomNavigation>
                </Paper>
            </Styled.BottomAppBarContainer>

        </>
    )
}
