import styled, { keyframes } from 'styled-components';
import Colors from '../../../constants/Colors';

export const BottomAppBarContainer = styled.div`
@media (min-width: 576px) {
    display:none;
  }
`;

export const Title = styled.div`
    font-size: 1.7rem; 
    font-weight: 500; 
    color: white;
`;

export const AppBarMenuDes = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 35px;

    @media (max-width: 576px) {
        display:none;
      }
`;

export const AppBarMenuBtnDes = styled.div`
    font-size: 1.4rem; 
    color:white; 
    cursor: pointer;
`;