import React, { useEffect, useState } from 'react'
import HomeAppBarHead from '../../components/HomeAppBarHead'
import { isExpired, decodeToken } from "react-jwt";
import { useNavigate } from 'react-router-dom'
import Colors from '../../constants/Colors';
import * as Styled from './styles';

function SettingsPage() {
  const navigate = useNavigate()
  const [token, settoken] = useState("")
  const [userDetails, setUserDetails] = useState({})
  const [gstValue, setGstValue] = useState(18)

  useEffect(() => {
    let tokenGot = localStorage.getItem("AppToken-SSC")
    console.log(tokenGot)
    settoken(tokenGot)
    const myDecodedToken = decodeToken(tokenGot);
    const isMyTokenExpired = isExpired(tokenGot);
    console.log(myDecodedToken)
    setUserDetails(myDecodedToken)
    if (isMyTokenExpired) {
      localStorage.removeItem("AppToken-SSC")
      navigate("/")
      return
    }
    setUserDetails(myDecodedToken)
  }, [])
  return (
    <Styled.MainContainer >
      <HomeAppBarHead userDetails={userDetails} page="Settings" />
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40px" }}>
        <div style={{ height: "500px", width: "500px", borderRadius: "10px", overflow: "hidden", backgroundColor: Colors.FOREGROUND_COLOR }}>
          <div style={{ backgroundColor: Colors.BLOUSE_COLOR, height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ color: "white", fontSize: "22px" }}>Settings</div>
          </div>

          <div style={{ width: "90%", marginLeft: "20px", marginTop: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div style={{ color: "white", fontSize: "16px" }}>Enter GST in % (0-100)</div>
              <input
                type="text"
                value={gstValue}
                style={{ width: "100%", height: "40px", fontSize: "18px", paddingLeft: "10px", borderRadius: "5px", backgroundColor: "black", border: "1px solid white", color: "white" }} ></input>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div style={{ color: "white", fontSize: "16px" }}>Shop Name</div>
              <input
                type="text"
                value={userDetails["userShopName"]}
                style={{ width: "100%", height: "40px", fontSize: "18px", paddingLeft: "10px", borderRadius: "5px", backgroundColor: "black", border: "1px solid white", color: "white" }} ></input>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div style={{ color: "white", fontSize: "16px" }}>Shop Address</div>
              <input
                type="text"
                value={userDetails["userShopAddress"]}
                style={{ width: "100%", height: "40px", fontSize: "18px", paddingLeft: "10px", borderRadius: "5px", backgroundColor: "black", border: "1px solid white", color: "white" }} ></input>
            </div>

          </div>

        </div>
      </div>


    </Styled.MainContainer>
  )
}

export default SettingsPage