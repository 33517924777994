import styled, { keyframes } from 'styled-components';
import Colors from '../../../constants/Colors';

export const SearchBlock = styled.div`
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    gap: 15px; 
    margin: 50px;
    @media (max-width: 576px) {
        flex-direction:column; 
        margin: 20px;
      }
`;

export const PageTitle = styled.div`
    font-size: 25px; 
    color: ${Colors.BLOUSE_COLOR}; 
    font-weight: 500;
`;

export const SearchInput = styled.input`
    height: 35px;
    width: 100%;
    border: 1px solid black;
    border-radius: 0px;

    :focus{
        border: 1px solid ${Colors.BLOUSE_COLOR};
        border-radius: 0px;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
`;

export const SearchButton = styled.button`
    background-color:${Colors.BLOUSE_COLOR};
    color:white;
    width:150px;
    font-size:16px;

`;

export const ProductBox = styled.div`
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color:#212529;
    border: 2px solid ${Colors.BLOUSE_COLOR};
    border-radius: 10px;
    height:130px;
    width:300px
`;

export const ProductBoxBtn = styled.div`
    cursor: pointer; 
    height: 33px; 
    width: 100px;
    color: white; 
    display: flex; 
    justify-content: center; 
    align-items: center; border-radius: 20px; 
    font-size: 18px;

    &.del{
        background-color: red; 
    }
    &.edit{
        background-color: #FF5B00; 
    }
    &.add{
        background-color: #309054; 
        
    }
    &.remove{
        background-color: red; 
        width: 150px;
        
    }
`;

export const TabBox = styled.div`
    display: flex; 
    gap: 25px; 
    justify-content: center; 
    margin-bottom: 45px; 
    overflow: auto;
    height:50px;
    @media (max-width: 576px) {
        justify-content: flex-start;
        gap: 35px;  
      }
      
      @media (min-width: 577px)
        and (max-width: 768px) {
      
            justify-content: flex-start; 
            gap: 35px; 
       
      }
`;

export const ModalAddButton = styled.button`
    background-color:${Colors.BLOUSE_COLOR};
    color:white;
    width:100px;
    height:40px;
    font-size:16px;
    cursor:pointer;
`;

export const ModalBtnBlock = styled.button`
    margin-left:20px; 
    margin-right:30px; 
    gap:25px; 
    display:flex; 
    flex-direction:row; 
    justify-content:flex-end;
    align-items:center;
`;


export const TabItem = styled.div`
    color: ${Colors.TEXT_COLOR};
    font-size: 20px;
    width: 140px; 
    height: 40px;
    align-items: center; 
    display: flex; 
    justify-content: center; 
    border-radius: 20px; 
    cursor: pointer;

    @media (max-width: 576px) {
        padding-left:10px;
        padding-right:10px;
      }
    
    &.selectedTab{
        background-color: ${Colors.BLOUSE_COLOR};
    }
    &.normalTab{
        background-color: transparent;
    }
    &:hover {
        border:1px solid ${Colors.BLOUSE_COLOR};
      }

    
`;

export const Text = styled.div`
  &.productName{
    color: ${Colors.TEXT_COLOR}; 
    font-size: 22px;
  }
  &.productPrize{
    color: #FCC809;
    font-size: 22px;
  }
`;

// export const ModalInputBlock = styled.div`
//     display:flex; 
//     flex-direction:column;
//     background-color:red;
//     margin-left:20px;
//     margin-right:30px; 
//     gap:5px; 
    
// `;


