import Feature1 from '../../assets/images/landingPageImages/feature1.svg'
import Feature2 from '../../assets/images/landingPageImages/feature2.svg'
import Feature3 from '../../assets/images/landingPageImages/feature3.svg'
import Feature4 from '../../assets/images/landingPageImages/feature4.svg'
import Feature5 from '../../assets/images/landingPageImages/feature5.svg'
import Feature6 from '../../assets/images/landingPageImages/feature6.svg'
import Feature7 from '../../assets/images/landingPageImages/feature7.svg'
import Feature8 from '../../assets/images/landingPageImages/feature8.svg'

export const featuresData = [
  {
    title: 'Customer Management',
    content: 'Keep a Data of Customer’s Names, Address, Phone Numbers and Measurements for future',
    image: Feature1
  },
  {
    title: 'Quick Bill Generation',
    content: 'Say Good Bye to Paper Works. Now Bills will generate quickly',
    image: Feature2
  },
  {
    title: 'Products Manager',
    content: 'Add Your Products and Update it regarding your needs',
    image: Feature3
  },
  {
    title: 'GST Billing & Discount',
    content: 'Bills will be Generated automatically for the Order. GST & Discount Options Available',
    image: Feature4
  },
  {
    title: 'Report Generation',
    content: 'Get a comprehensive report on the overall sales you have received over the past few months and years',
    image: Feature5
  },
  {
    title: 'No More Calculations',
    content: 'Balance Amount for Customer will be automatically generated',
    image: Feature6
  },
  {
    title: 'Smart Shop Calculator Mini ',
    content: 'Available in Play Store as Smart Shop Calculator Mini Mobile App',
    image: Feature7
  },
  {
    title: 'Fits in All Devices',
    content: 'Can be viewed in all Laptops, Tablets & Mobile Phones',
    image: Feature8
  },
]