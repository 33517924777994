import styled, { keyframes } from 'styled-components';
import Colors from '../../../constants/Colors';

export const MainContainer = styled.div`
    display: flex; 
    height: 100vh; 
    flex-direction: column; 
    flex: 1;
`;

export const HomeBlock = styled.div`
    display:block; 
    overflow: auto; 
    flex:1;
`;

export const HomeSubBlock = styled.div`
    display:flex; 
    height:100%;
`;

export const HomeSubProductsBlock = styled.div`
   
    width:200%;
`;

export const HomeSubBillBlock = styled.div`
    background-color: grey; 
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`;

export const DeleteIcon = styled.div`
    color: red;
    cursor: pointer;
    cursor: hand;    
`;

export const MinusPlusBtn = styled.div`
    color:white; 
    font-size:16px;
    cursor: pointer;
    cursor: hand;    
`;

export const QuantityText = styled.div`
    color:white; 
    font-size:16px;  
`;

export const QuantityBlock = styled.div`
    display:flex; 
    gap:25px; 
    border: 1px solid #ffffff; 
    padding:5px 15px 5px 15px;
    border-radius:5px; 
`;

export const Text = styled.div`
    color: white;
    &.billItemHead{
      opacity: 0.5;
    }
    &.billItemContent{
      font-size: 18px; 
    }
    &.billItemDelete{
      font-size: 18px; 
      cursor: pointer;
    }
`;

export const BillItemBlock = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 5px;
`;

export const DeleteBillItem = styled(BillItemBlock)`
    background-color: red; 
    padding: 5px; 
    border-radius: 5px;
`;