import { toast } from 'react-toastify';

export const showToast = (message, mode) => {
    if (mode === "warn") {
        toast.warn(message, {
            position: "bottom-right",
            autoClose: 5000,
            pauseOnHover: true,
            theme: "dark",
        });
    }
    if (mode === "success") {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            pauseOnHover: true,
            theme: "dark",
        });
    }
    if (mode === "info") {
        toast.info(message, {
            position: "bottom-right",
            autoClose: 5000,
            pauseOnHover: true,
            theme: "dark",
        });
    }
    if (mode === "error") {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            pauseOnHover: true,
            theme: "dark",
        });
    }

}